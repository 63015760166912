import request from '../utils/request'

// 查询
export function pipeOption(params) {
  return request({
    url: '/statistics/getStatisticsPipeOption',
    method: 'get',
    params
  })
}

// 新增节点
export function saveNode(params) {
  return request({
    url: '/point/save',
    method: 'POST',
    data: params
  })
}
// 查看节点
export function pointFindById(params) {
  return request({
    url: '/point/findById',
    method: 'get',
    params
  })
}

export function getWorkHiddenById(params){
  return request({
    url: '/gis/basic/sign/workHidden/getWorkHiddenById',
    method: 'get',
    params
  })
}
export function getDetailById(params){
  return request({
    url: '/gis/basic/sign/detectionPoint/getDetailById',
    method: 'get',
    params
  })
}

// 修改节点
export function updateById(params) {
  return request({
    url: '/point/update',
    method: 'POST',
    data: params
  })
}
// 删除节点
export function pointDelete(params) {
  return request({
    url: '/point/delete',
    method: 'POST',
    data: params
  })
}
// 节点-移除设备
export function removePointById(params) {
  return request({
    url: '/point/remove',
    method: 'get',
    params
  })
}

// 新增监测点
export function saveMonitor(params) {
  return request({
    url: '/monitorPoint/save',
    method: 'POST',
    data: params
  })
}
// 修改监测点
export function updateMonitor(params) {
  return request({
    url: '/monitorPoint/update',
    method: 'POST',
    data: params
  })
}
// 查询监测点详情
export function monitorFindById(params) {
  return request({
    url: '/monitorPoint/findById',
    method: 'get',
    params
  })
}
// 删除监测点
export function monitorDelete(params) {
  return request({
    url: '/monitorPoint/delete',
    method: 'get',
    params
  })
}
// 新增监测点上的流量计
export function saveFlow(params) {
  return request({
    url: '/flow/save',
    method: 'POST',
    data: params
  })
}
// 新增监测点上的压力计
export function savePressure(params) {
  return request({
    url: '/pressure/save',
    method: 'POST',
    data: params
  })
}
// 删除监测点上的流量计
export function flowDelete(params) {
  return request({
    url: '/flow/delete',
    method: 'get',
    params
  })
}
// 删除监测点上的压力计
export function pressureDelete(params) {
  return request({
    url: '/pressure/delete',
    method: 'get',
    params
  })
}
// 查询监测点上的流量计详情
export function flowFindById(params) {
  return request({
    url: '/flow/findById',
    method: 'get',
    params
  })
}
// 查询监测点上的压力计详情
export function pressureFindById(params) {
  return request({
    url: '/pressure/findById',
    method: 'get',
    params
  })
}
// 修改监测点上的流量计
export function flowUpdateById(params) {
  return request({
    url: '/flow/update',
    method: 'POST',
    data: params
  })
}
// 修改监测点上的压力计
export function pressureUpdateById(params) {
  return request({
    url: '/pressure/update',
    method: 'POST',
    data: params
  })
}

// 新增管线
export function saveLine(params) {
  return request({
    url: '/pipe/batchSave',
    method: 'POST',
    data: params
  })
}
//查看管线
export function pipeFindById(params) {
  return request({
    url: '/pipe/findById',
    method: 'get',
    params
  })
}
// 修改管线
export function pipeUpdate(params) {
  return request({
    url: '/pipe/update',
    method: 'POST',
    data: params
  })
}
// 删除管线
export function pipeDelete(params) {
  return request({
    url: '/pipe/delete',
    method: 'get',
    params
  })
}
export function cancelWorkHidden(params) {
  return request({
    url: '/gis/basic/sign/workHidden/cancelWorkHidden',
    method: 'get',
    params
  })
}
export function detectionPointDelete(params) {
  return request({
    url: '/gis/basic/sign/detectionPoint/delete',
    method: 'get',
    params
  })
}

// 新增阀门
export function saveValve(params) {
  return request({
    url: '/valve/save',
    method: 'POST',
    data: params
  })
}
// 修改阀门
export function valueUpdate(params) {
  return request({
    url: '/valve/update',
    method: 'POST',
    data: params
  })
}

//查看阀门by节点id
export function valveFindByPointId(params) {
  return request({
    url: '/valve/findByPointId',
    method: 'get',
    params
  })
}
export function valveFindById(params) {
  return request({
    url: '/valve/findById',
    method: 'get',
    params
  })
}
//删除阀门
export function valveDelete(params) {
  return request({
    url: '/valve/delete',
    method: 'get',
    params
  })
}

// 新增场站
export function saveStation(params) {
  return request({
    url: '/station/save',
    method: 'POST',
    data: params
  })
}

// 是否为中压自由端
export function findIsPressureFree(params) {
  return request({
    url: '/point/isPressureFree',
    method: 'get',
    params
  })
}

// 跟节点有关的管线
export function pipeLink(params) {
  return request({
    url: '/pipe/link',
    method: 'get',
    params: params
  })
}
// 打断 点
export function pipePointBreak(params) {
  return request({
    url: '/pipe/pointBreak',
    method: 'POST',
    data: params
  })
}
// 打断线
export function pipeLineBreak(params) {
  return request({
    url: '/pipe/lineBreak',
    method: 'POST',
    data: params
  })
}

// 拖拽管线连接的点
export function pipeDrag(params) {
  return request({
    url: '/pipe/drag',
    method: 'POST',
    data: params
  })
}
//拖拽节点
export function pointDragPoint(params) {
  return request({
    url: '/point/dragPoint',
    method: 'POST',
    data: params
  })
}
//场站
export function pointDragStation(params) {
  return request({
    url: '/station/dragPoint',
    method: 'POST',
    data: params
  })
}
//拖拽摄像头
export function cameraUpdatePoint(params) {
  return request({
    url: '/camera/dragPoint',
    method: 'POST',
    data: params
  })
}

//拖拽检测点
export function monitorPointDragPoint(params) {
  return request({
    url: '/monitorPoint/dragPoint',
    method: 'POST',
    data: params
  })
}

//拖拽泄露报警
export function warningUpdatePoint(params) {
  return request({
    url: '/warning/dragPoint',
    method: 'POST',
    data: params
  })
}

//查看场站
export function stationFindByIds(params) {
  return request({
    url: '/station/findById',
    method: 'get',
    params
  })
}
//修改场站
export function stationUpdate(params) {
  return request({
    url: '/station/update',
    method: 'POST',
    data: params
  })
}

//查询节点列表by进出站节点id
export function findStationPoint(params) {
  return request({
    url: '/station/findByPointId',
    method: 'get',
    params
  })
}
//新增进出站节点
export function saveStationPoint(params) {
  return request({
    url: '/station/savePoint',
    method: 'POST',
    data: params
  })
}
//修改进出站节点
export function updateStationPoint(params) {
  return request({
    url: '/station/updatePoint',
    method: 'POST',
    data: params
  })
}
//清除进出站节点
export function clearStationPoint(params) {
  return request({
    url: '/station/clearPoint',
    method: 'get',
    params
  })
}

// 连通性分析--等待时间长一点
export function pipeNetwork(params) {
  return request({
    url: '/pipe/network',
    method: 'get',
    timeout: 1000 * 60 * 3,
    params
  })
}
export function toolbarChooseDelete(data) {
  return request({
    url: '/toolbar/choose/delete',
    method: 'post',
    data
  })
}
// 根据gis场站id查询scada场站
export function selectStationByGisId(params) {
  return request({
    url: '/scada/station/selectStationByGisId',
    method: 'get',
    params
  })
}